var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":"","color":"transparent"}},[_c('v-card-text',{staticClass:"pt-0 px-0"},[_c('div',{staticClass:"attendances-inputs-container"},[_c('div',{staticClass:"attendances-inputs-section-1"},[_c('div',{staticClass:"attendances-inputs-section-1-input-1"},[_c('AlternativeAutocompleteOne',{attrs:{"label":"Empresa","items":_vm.serverItems.companies,"valueInput":_vm.selectedBusiness,"loading":_vm.loadingCompanies},on:{"valueChange":function (v) {_vm.selectedBusiness = v; this$1.index()}}})],1),_c('div',{staticClass:"attendances-inputs-section-1-input-2"},[_c('AlternativeAutocompleteOne',{attrs:{"label":"Año","items":_vm.years,"valueInput":_vm.selectedYear,"loading":false},on:{"valueChange":function (v) {_vm.selectedYear = v; this$1.index()}}})],1),_c('div',{staticClass:"attendances-inputs-section-1-input-3"},[_c('AlternativeAutocompleteOne',{attrs:{"items":_vm.weeks,"label":"Semana","valueInput":_vm.selectedWeek,"loading":false},on:{"valueChange":function (v) {_vm.selectedWeek = v; this$1.index()}}})],1),_c('div',{staticClass:"ml-0 ml-md-0 attendances-inputs-section-1-input-4"},[_c('alternativeButton',{staticClass:"mr-1 btn x",attrs:{"props":_vm.returnBtnData}}),_c('alternativeButton',{staticClass:"btn",attrs:{"props":_vm.nextBtnData}})],1)]),_c('div',{staticClass:"attendances-inputs-section-2"},[_c('div',{directives:[{name:"permission",rawName:"v-permission",value:('attendances.create'),expression:"'attendances.create'"}],staticClass:" "},[_c('primaryButton',{attrs:{"props":_vm.uploadBtnData}})],1),_c('div',{staticClass:"attendances-buttons-container"},[_c('iconButton',{attrs:{"props":{
                                color:'primary',
                                icon:'mdi-information-outline',
                                iconClass: '',
                                height:'35',
                                click: function () {_vm.openFormatModal()}
                                }}}),_c('div',{staticClass:"attendances-view-button"},[_c('v-btn-toggle',{attrs:{"mandatory":"","active-class":"attendances-active-view-button","color":"white","group":""},model:{value:(_vm.indexView),callback:function ($$v) {_vm.indexView=$$v},expression:"indexView"}},[_c('v-btn',{staticClass:"btn-disabled ",attrs:{"height":"35","outlined":"","active-class":"btn-active primary"}},[_c('v-icon',[_vm._v("mdi-view-dashboard-outline")])],1),_c('v-btn',{staticClass:"btn-disabled ",attrs:{"height":"35","outlined":"","active-class":"btn-active primary"}},[_c('v-icon',[_vm._v("mdi-format-list-bulleted")])],1)],1)],1)],1)])]),(_vm.indexView == 0)?_c('v-row',[_c('v-col',[_c('datatable',{attrs:{"props":_vm.tableProps},on:{"method_handler":_vm.method_handler},scopedSlots:_vm._u([{key:"item.monday",fn:function(ref){
                                var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-chip',{staticClass:"chip-day-type",attrs:{"color":_vm.getChipColor(item.monday),"label":"","pill":""}},[_vm._v(" "+_vm._s(item.monday))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getTypeValue(item.monday)))])])]}},{key:"item.tuesday",fn:function(ref){
                                var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-chip',{staticClass:"chip-day-type",attrs:{"color":_vm.getChipColor(item.tuesday),"label":"","pill":""}},[_vm._v(" "+_vm._s(item.tuesday))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getTypeValue(item.tuesday)))])])]}},{key:"item.wednesday",fn:function(ref){
                                var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-chip',{staticClass:"chip-day-type",attrs:{"color":_vm.getChipColor(item.wednesday),"label":"","pill":""}},[_vm._v(" "+_vm._s(item.wednesday))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getTypeValue(item.wednesday)))])])]}},{key:"item.thursday",fn:function(ref){
                                var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-chip',{staticClass:"chip-day-type",attrs:{"color":_vm.getChipColor(item.thursday),"label":"","pill":""}},[_vm._v(" "+_vm._s(item.thursday))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getTypeValue(item.thursday)))])])]}},{key:"item.friday",fn:function(ref){
                                var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-chip',{staticClass:"chip-day-type",attrs:{"color":_vm.getChipColor(item.friday),"label":"","pill":""}},[_vm._v(" "+_vm._s(item.friday))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getTypeValue(item.friday)))])])]}},{key:"item.saturday",fn:function(ref){
                                var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-chip',{staticClass:"chip-day-type",attrs:{"color":_vm.getChipColor(item.saturday),"label":"","pill":""}},[_vm._v(" "+_vm._s(item.saturday))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getTypeValue(item.saturday)))])])]}},{key:"item.sunday",fn:function(ref){
                                var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-chip',{staticClass:"chip-day-type",attrs:{"color":_vm.getChipColor(item.sunday),"label":"","pill":""}},[_vm._v(" "+_vm._s(item.sunday))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getTypeValue(item.sunday)))])])]}}],null,true)})],1)],1):_vm._e(),(_vm.indexView == 1)?_c('v-row',[_c('v-col',[_c('datatable',{attrs:{"props":_vm.tableTimeProps},on:{"method_handler":_vm.method_handler},scopedSlots:_vm._u([{key:"item.monday",fn:function(ref){
                                var item = ref.item;
return [(item.mondayIsTime == false)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-chip',{staticClass:"chip-day-type",attrs:{"color":_vm.getChipColor(item.monday),"label":"","pill":""}},[_vm._v(" "+_vm._s(item.monday))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getTypeValue(item.monday)))])]):_vm._e(),(item.mondayIsTime == true)?_c('span',{staticClass:"prewrap"},[_vm._v(_vm._s(item.monday))]):_vm._e()]}},{key:"item.tuesday",fn:function(ref){
                                var item = ref.item;
return [(item.tuesdayIsTime == false)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-chip',{staticClass:"chip-day-type",attrs:{"color":_vm.getChipColor(item.tuesday),"label":"","pill":""}},[_vm._v(" "+_vm._s(item.tuesday))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getTypeValue(item.tuesday)))])]):_vm._e(),(item.tuesdayIsTime == true)?_c('span',{staticClass:"prewrap"},[_vm._v(_vm._s(item.tuesday))]):_vm._e()]}},{key:"item.wednesday",fn:function(ref){
                                var item = ref.item;
return [(item.wednesdayIsTime == false)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-chip',{staticClass:"chip-day-type",attrs:{"color":_vm.getChipColor(item.wednesday),"label":"","pill":""}},[_vm._v(" "+_vm._s(item.wednesday))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getTypeValue(item.wednesday)))])]):_vm._e(),(item.wednesdayIsTime == true)?_c('span',{staticClass:"prewrap"},[_vm._v(_vm._s(item.wednesday))]):_vm._e()]}},{key:"item.thursday",fn:function(ref){
                                var item = ref.item;
return [(item.thursdayIsTime == false)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-chip',{staticClass:"chip-day-type",attrs:{"color":_vm.getChipColor(item.thursday),"label":"","pill":""}},[_vm._v(" "+_vm._s(item.thursday))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getTypeValue(item.thursday)))])]):_vm._e(),(item.thursdayIsTime == true)?_c('span',{staticClass:"prewrap"},[_vm._v(_vm._s(item.thursday))]):_vm._e()]}},{key:"item.friday",fn:function(ref){
                                var item = ref.item;
return [(item.fridayIsTime == false)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-chip',{staticClass:"chip-day-type",attrs:{"color":_vm.getChipColor(item.friday),"label":"","pill":""}},[_vm._v(" "+_vm._s(item.friday))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getTypeValue(item.friday)))])]):_vm._e(),(item.fridayIsTime == true)?_c('span',{staticClass:"prewrap"},[_vm._v(_vm._s(item.friday))]):_vm._e()]}},{key:"item.saturday",fn:function(ref){
                                var item = ref.item;
return [(item.saturdayIsTime == false)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-chip',{staticClass:"chip-day-type",attrs:{"color":_vm.getChipColor(item.saturday),"label":"","pill":""}},[_vm._v(" "+_vm._s(item.saturday))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getTypeValue(item.saturday)))])]):_vm._e(),(item.saturdayIsTime == true)?_c('span',{staticClass:"prewrap"},[_vm._v(_vm._s(item.saturday))]):_vm._e()]}},{key:"item.sunday",fn:function(ref){
                                var item = ref.item;
return [(item.sundayIsTime == false)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-chip',{staticClass:"chip-day-type",attrs:{"color":_vm.getChipColor(item.sunday),"label":"","pill":""}},[_vm._v(" "+_vm._s(item.sunday))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getTypeValue(item.sunday)))])]):_vm._e(),(item.sundayIsTime == true)?_c('span',{staticClass:"prewrap"},[_vm._v(_vm._s(item.sunday))]):_vm._e()]}}],null,true)})],1)],1):_vm._e()],1)],1),_c('modal',{attrs:{"props":_vm.modalFormatProps},on:{"modalResponse":_vm.method_handler}},[_c('template',{slot:"cardBody"},[_c('span',[_vm._v("1.- Conserva todos los encabezados en el orden presentado y sin modificarlos.")]),_c('br'),_c('span',[_vm._v("2.- Llena la información de acuerdo a los campos solicitado y no utilices comas en ninguno de los campos.")]),_c('br'),_c('span',[_vm._v("3.- El archivo debe estar en formato CSV")]),_c('br'),_c('span',[_vm._v("4.- La fecha debe escribirse en formato DD/MM/AAAA")]),_c('br'),_c('span',[_vm._v("5.- Las horas de entrada y salida son en formato 24 horas HH:MM")]),_c('br'),_c('span',[_vm._v("6.- A continuación se muestra una línea de ejemplo acorde al formato.")]),_c('br'),_c('datatable',{attrs:{"props":_vm.tableFormatProps},on:{"method_handler":_vm.method_handler}})],1),_c('template',{slot:"cardActions"},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('secondaryButton',{attrs:{"props":_vm.donwloadFormatModal}})],1),_c('v-col',{staticClass:"mr-sm-4",attrs:{"cols":"12","sm":"2"}},[_c('primaryButton',{attrs:{"props":_vm.acceptFormatModal}})],1)],1)],1)],2),_c('input',{ref:"uploader",staticClass:"d-none",attrs:{"type":"file"},on:{"change":_vm.onFileChanged}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }